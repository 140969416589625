<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.User_Courses') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="hiddenColTable"
                            :items="coursesOfUsers"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoadingCoursesOfUser"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-spacer></v-spacer>

                                    <div class="search">
                                        <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select>
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>

                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete :closeDelete="closeDelete" :deleteItemConfirm="deleteItemConfirm">
                                        </ModalDelete>
                                    </v-dialog>
                                    <v-dialog v-model="dialogNoti" max-width="500px">
                                        <ModalNoti :close="closeNoti" :message="formMessage"></ModalNoti>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.title`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.title }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.completion_rate`]="{ item }">
                                <v-progress-linear
                                    :value="item.course_records[0].progress || 0"
                                    height="25"
                                    :style="{ width: '75%' }"
                                >
                                    <strong>{{ Math.ceil(item.course_records[0].progress || 0) }}%</strong>
                                </v-progress-linear>
                            </template>
                            <template v-slot:item.record="{ item }">
                                <router-link
                                    :to="{ path: `/chapters-of-course?user_id=${user_id}&course_id=${item.id}` }"
                                >
                                    <v-btn color="indigo" dark small>
                                        <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                        {{ $t('cms.chapters_of_course') }}
                                    </v-btn>
                                </router-link>
                            </template>
                        </v-data-table>
                        <div class="text-center py-5">
                            <f-pagination
                                v-model="page"
                                :pageSize="coursesOfUserPagination.pageSize"
                                :totalItems="coursesOfUserPagination.totalItems"
                                :disabled="isLoadingCoursesOfUser"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24"></a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS/Modal/Noti.vue';
import { mixinToasts, mixinFormats } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinToasts, mixinFormats],
    components: { ModalDelete, ModalNoti },

    data() {
        return {
            tableColumns: [
                { text: this.$t('cms.course_name'), value: 'title', sortable: false, width: '300px' },
                { text: this.$t('cms.completion_rate'), value: 'completion_rate', sortable: false, width: '300px' },
                { text: this.$t('cms.chapters_of_course'), value: 'record', sortable: false, width: '200px' },
            ],
            fieldsSearch: [
                {
                    title: this.$t('cms.course_name'),
                    value: 'title',
                },
            ],
            selectedField: 'title',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
            user_id: '',
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
        const id = this.$router?.history?.current?.query?.user_id;
        this.user_id = id;
    },
    computed: {
        ...mapState('coursesOfUser', ['isLoadingCoursesOfUser', 'coursesOfUsers', 'coursesOfUserPagination']),
        formTitle() {
            return this.cuIndex === -1 ? 'New Item' : 'Edit Item';
        },
        hiddenColTable() {
            return this.tableColumns.filter((s) => s.value !== 'password');
        },
    },
    async mounted() {
        await this.getCoursesOfUserList({ page: this.page, field: this.selectedField, search: this.search });
    },
    watch: {
        page(val) {
            this.getCoursesOfUserList({ page: val, field: this.selectedField, search: this.search });
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
    },
    methods: {
        ...mapActions('coursesOfUser', [
            'getCoursesOfUsers',
            'postNewCoursesOfUser',
            'putOneCoursesOfUser',
            'deleteOneCoursesOfUser',
        ]),
        async getCoursesOfUserList(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field;
            this.search = data?.search || '';
            await this.getCoursesOfUsers({ id: this.user_id, ...data });
        },

        initialize() {
            this.getCoursesOfUserList({ page: 1, field: 'title', search: '' });
        },
        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },
        onAddNewItem() {
            this.dialogEdit = false;
            this.dialogCU = true;
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.coursesOfUsers.indexOf(item.id);
            this.cuItem = Object.assign({}, item);
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.coursesOfUsers.indexOf(item.id);
            this.cuItem = Object.assign({}, item);
        },
        onDeleteItem(item) {
            this.cuIndex = this.coursesOfUsers.indexOf(item);
            this.cuItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            // this.coursesOfUsers.splice(this.cuIndex, 1);
            const res = await this.deleteOneCoursesOfUser(this.cuItem.id);
            if (res?.isSuccess) {
                await this.getCoursesOfUserList({ page: this.page, field: this.selectedField, search: this.search });
                this.showToast('success', res?.data?.message);
            } else {
                this.showError(res?.mess);
            }
            this.closeDelete();
        },
        closeCU() {
            this.dialogCU = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeNoti() {
            this.dialogNoti = false;
        },
        async onSaveItem() {
            this.dialogNoti = true;
            if (this.cuIndex === -1) {
                const res = await this.postNewCoursesOfUser(this.cuItem);
                if (res?.isSuccess) {
                    this.formMessage = res?.mess || 'Created success!';
                    await this.getCoursesOfUserList({ page: 1, field: 'title', search: '' });
                } else {
                    this.formMessage = res?.mess || 'Created fail!';
                }
            } else {
                const res = await this.putOneCoursesOfUser(this.cuItem);
                if (res?.isSuccess) {
                    this.formMessage = res?.mess || 'Updated success!';
                    await this.getCoursesOfUserList({
                        page: this.page,
                        field: this.selectedField,
                        search: this.search,
                    });
                } else {
                    this.formMessage = res?.mess || 'Updated fail!';
                }
            }
        },
        onSearchItem: _.debounce(function (e) {
            this.getCoursesOfUserList({ page: 1, field: this.selectedField, search: this.search });
        }, 500),
        handleChangeSelect(value) {
            this.getCoursesOfUserList({ page: 1, field: value, search: this.search });
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}
</style>
